<template>
    <div class="mobileSideBar">
        <template v-if="toggleOn.some(i => i == $store.getters.currentDevice)"> 
            <v-button @click="isActive = !isActive; HOLDER_BAR(isActive)" class="button button_size_large button_color_black mobileSideBar__button"
                :class="{'mobileSideBar__button_active':isActive}"
            >
                <span></span>
                <span></span>
                <span></span>
            </v-button>
            <transition name="swipe-left" mode="out-in">
                <div class="mobileSideBar__holder" :class="{'mobileSideBar__holder_active':isActive}">
                    <slot></slot>
                </div>
            </transition>
        </template> 
        <slot v-else ></slot> 
    </div>
</template>

<script>

import VSideSwipeBar from '@components/global/SideSwipeBar'
import vButton from '@components/global/vButton';
import { mapActions } from 'vuex'

export default {
    props: {
        toggleOn: {
            type: [Array],
            default: () => []
        }
    },
    data() {
        return {
            isActive: false
        }
    },
    components: {
        VSideSwipeBar,
        vButton
    },
    methods: {
        ...mapActions([
            'HOLDER_BAR'
        ]),
    }
}
</script>

<style lang="scss">
.mobileSideBar {
    
    &__button {
        position: relative;
        z-index:2;
        margin-right: 0px;
        &> span {
            width: 20px
        }
        span > span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 2px;
            background-color: #fff;

            opacity: 1;
            &:first-child {
                top: -12px
            }
            &:last-child {
                bottom: -12px
            }
            transition: top .3s, bottom .3s, transform .0s, opacity .0s;
        }
        &_active {
            
            span > span {
                transition: top .0s, bottom .0s, transform .3s, opacity .3s;
                transform:rotate(45deg);

                &:first-child {
                    top: 0;
                    transform:rotate(45deg);
                    opacity: 0;
                }
                &:last-child {
                    bottom: 0;
                    transform:rotate(-45deg);
                }
            }
        }

       
    }

    &__holder {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        overflow: hidden;

        &_active {
            left: 0;
            transition: .3s;
            overflow: visible;
        }
    }
    
}

</style>