<template>
    <v-side-swipe-bar :show="$store.getters.barPay.value" @hide="$emit('hide')" :outsideDisabed="true">
        <div class="qpay__header" slot="header">
            <p> Быстрые выплаты </p>
        </div>
        <div slot="body">

            <div class="form__row" v-if="!selected">
                <v-driver-search
                    class="size_large"
                    icon="#icon-search"
                    placeholder="Введите"
                    label="Поиск водителя"
                    buttonIcon="icon-loading"
                    @select="HandlerSelectDriver($event)"
                />
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="selectedDriverDetail">
                    <div class="form__row">
                        <p class="qpay__title">
                            Информация о водители
                        </p> 
                        <ul class="qpay__list">
                            <li class="qpay__item qpay__item_color_purpl" @click="HandleDriverClick">
                                <router-link :to="{name: 'DriverDetail', query: { driver_id:selectedDriverDetail.id, park_id: selectedDriverDetail.park.id }}">
                                    ФИО: {{ selectedDriverDetail.full_name.text}}
                                </router-link>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Телефон: {{ selectedDriverDetail.phone}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    <strong>
                                        Баланс:
                                         <span :class="{
                                                'qpay__span_color_red': +selectedDriverDetail.balance.replace(' ','') <=0,
                                                'qpay__span_color_green': +selectedDriverDetail.balance.replace(' ','') >=0
                                            }">
                                            {{ selectedDriverDetail.balance}}
                                        </span>
                                </strong>
                                </p>
                            </li>
                            <!-- <li class="qpay__item">
                                <p>
                                    <strong>
                                        Доступно:
                                         <span :class="{
                                                'qpay__span_color_red': +selectedDriverDetail.balance.replace(' ','')*0.01 <=0,
                                                'qpay__span_color_green': +selectedDriverDetail.balance.replace(' ','')*0.01 >=0
                                            }">
                                            {{ +selectedDriverDetail.balance.replace(' ','') - (+selectedDriverDetail.balance.replace(' ','')*0.01)}}
                                        </span>
                                </strong>
                                </p>
                            </li> -->
                            <li class="qpay__item">
                                <p>
                                    Город: {{ selectedDriverDetail.park_name}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Условие работы: {{ selectedDriverDetail.work_rule}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Позывной: {{ selectedDriverDetail.call_sign}}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="form__row">
                        <p class="qpay__title">
                            Выплата для водителя
                        </p>
                    </div>
                    <div class="form__row">
                        <v-custom-select
                            class="size_large"
                            :list="provider.list"
                            :value="provider.item.id"
                            placeholder="Выберите провайдер"
                            label="Провайдер"
                            :error="error?error.provider ||'' : ''"
                            @update="HandleProviderSelect($event)"
                            :disabled="provider.isload || isFormLoad"
                        />
                    </div>

                    <div class="form__row" v-if="provider.item.id && provider.item.is_external_service && service.list.length">
                        
                        <v-custom-search-select
                            class="size_large"
                            :list="service.list"
                            v-model="service.item.id"
                            placeholder="Выберите Услугу"
                            label="Услуги"
                            buttonIcon="#icon-close"
                            @input="HandleServiceSelect($event)"
                            @buttonClick = "HandleClickRemoveService()"
                            :error="error?error.service_id ||'' : ''"
                            :disabled="service.isload || isFormLoad"
                            :buttonIconClass= "{ 'button_color_red': true, 'button_hidden': !this.account.is }"
                        />

                    </div>
                    <div class="form__row" v-if="retr.list.length && service.item.id">
                        <v-custom-select
                            class="size_large"
                            :list="retr.list"
                            v-model="retr.selected"
                            placeholder="Выберите Шаблон"
                            label="Шаблон"
                            @input="HandleRetrSelect"
                        />
                    </div>

                    <div class="form__row">
                        <v-custom-input
                            class="size_large"
                            placeholder="Сумма для вывода"
                            label="Сумма"
                            v-model="amount"
                            :error="error? error.amount : ''"
                            @input="handlAmountChange"
                            :disabled="!provider.item.id || isFormLoad"
                            maxlength=7
                            :notes="commission.commission? 'Коммиссия:'+commission.commission : ''"
                            :customError="'Допустимая сумма вывода от ${minValue} до ${maxValue} '"
                            :minValue="commission.min_sum"
                            :maxValue="commission.max_sum || commission.available_amount"
                        />
                        <div v-if="commission.min_sum" class="validation__label validation__label_type_text-teft">
                            Минимальная сумма для вывода: {{commission.min_sum}}
                        </div>
                        <div v-if="commission.max_sum" class="validation__label validation__label_type_text-teft">
                            Максимальная сумма для вывода за раз: {{commission.max_sum}}
                        </div>

                        <div v-if="commission.available_amount" class="validation__label validation__label_type_text-teft">
                            Доступная сумма для вывода: {{commission.available_amount}}
                        </div>
                    </div>
                    <div class="form__row">
                        <v-check-box class="size__large checkbox-label" v-model="is_save_template" label="Сохранить как шаблон"/>
                    </div>
                    <div class="form__row">
                        <v-custom-autocomplete
                            v-if="cardLisit && !this.account.is"
                            class="size_large"
                            placeholder="Не введено"
                            label="Карта для вывода"
                            v-model="driversCard"
                            :mask="{
                                mask:'9999-9999-9999-9999'
                            }"
                            :list="filteredCardList"
                            :buttonIcon="driversCardIcon"
                            @input="HandleCardInput()"
                            @listButtonClick="removeCard($event)"
                            :error="error?error.number ||'' : ''"
                            :isload="cardLisitUpdated || isFormLoad"
                            :iconSize="{w:16, h:16}"
                        />

                        <v-custom-input
                            v-if="this.account.is"
                            class="size_large"
                            placeholder="Введите реквизиты"
                            label="Реквезиты"
                            v-model="account.value"
                            :error="error? error.account : ''"
                            :mask="{
                                mask: account.mask
                            }"  
                            :maxlength = account.length
                            :disabled="isFormLoad"
                        />
                    </div>
                    <div class="form__row form__row_type_bottom">
                        <v-button @click="HandleClick" 
                            class="qpay__btn button button_size_large button_color_black"
                            :disabled=" isFormLoad ||  !provider.item.id || (commission ? !commission.can_provider_sum : false) || (account.is ? !account.value : !driversCard.text )"
                        >
                            Выплатить
                        </v-button>
                    </div>
                    
                </div>
            </transition>
        </div>
    </v-side-swipe-bar>
</template>

<script>

import api from '../../../api'
import vSideSwipeBar from '../../global/SideSwipeBar'
import vCustomInput from '../../global/CustomInput'
import vSwitcher from '../../global/CustomSwitcher'
import vCustomAutocomplete from '../../global/CustomAutocomplete'
import vDriverSearch from './DriverSearch'
import vButton from '@components/global/vButton'
import { eventBus } from '@eventBus'
import vCustomSelect from '../../global/CustomSelect.vue'
import vCustomSearchSelect from '../../global/CustomSearchSelect'
import vCheckBox from '@components/global/CheckBox'
import { mapActions } from 'vuex'
export default {
    name:'SideQuickPay',
    props: {
        selected: {
            type: [Object, Boolean],
            default: () => {}
        }
    },
    components: {
        vSideSwipeBar,
        vCustomInput,
        vCustomAutocomplete,
        vSwitcher,
        vDriverSearch,
        vCustomSelect,
        vCustomSearchSelect,
        vButton,
        vCheckBox
    },
    data() {
        return {
            switcher: false,
            isDriverClear: false,
            can: null,
            isFormLoad: false,
            inputDelay: 500,
            timer: {},
            driversList: [],
            isSearchLoadding: null,
            selectedDriverDetail: null,
            cardLisit: null,
            cardLisitUpdated: false,
            filteredCardList: null,
            driversCard: {
                text:''
            },
            error: null,
            is_save_template: false,
            search: {
                id: null,
                text: ''
            },
            retr: {
                list: [],
                selected: null
            },
            amount: '',
            number: '',
            provider: {
                item: {id: null},
                list:[],
                isload: false
            },
            service: {
                item: {},
                list: [],
                isload: false
            },
            commission: {
                time: null,
                available_amount: null,
                balance_after_withdraw: null,
                can_provider_sum: null,
                commission: null,
                min_sum: null,
                max_sum: null,
            },
            account : {
                is: false,
                mask: '',
                length: '',
                value: ''
            },
        }
    },
    watch: {
        selected(newValue, oldValue) {
            this.search = newValue
            if(newValue) {
                this.getDetail()
            } else {
                this.selectedDriverDetail = false
            }
        }
    },
    computed: { 
        driversCardIcon() {
            return this.cardLisit && this.cardLisit.length ? '#icon-arrow-down':''
        }
    },
    mounted() {
        if(this.selected) {
            this.search = this.selected
            this.getDetail()
        }
    },
    methods: {
        ...mapActions([
            'TRIGGER_BAR'
        ]),
        HandleDriverClick() {
            this.$router.push()
        },
        HandleClickRemoveService() {
            this.account.mask = ''
            this.account.length = ''
            this.account.is = false
            this.account.value = ''
            this.service.item = { id: null}


        },
        HandleServiceSelect(item) {
            this.getAccount(item.id).then(res=>{
                const {mask, length} = res
                this.account.mask = mask || ''
                this.account.length = length || ''
                this.account.is = true

                const driver_id = this.selectedDriverDetail.id
                const park_id = this.selectedDriverDetail.park.id
                const service_id = this.service.item.id.id

                this.getTemplate({driver_id, park_id, service_id}).then(res=>{
                    this.retr.list = res.response
                })
            })
        },
        HandleRetrSelect(item) {
            const {account, amount} = this.retr.list.find(templ=>templ.id == item)
            this.account.value = account
            this.amount = amount
            this.handlAmountChange()
        },
        handlAmountChange() {
            const driver = this.selectedDriverDetail
            const provider = this.provider.item
            this.commission.time = new Date().getTime()
            const payload = { 
                id:driver.id,
                park: driver.park.id,
                amount: this.amount || 0,
                time: this.commission.time,
                payment_provider_id: provider.id
            }
            api.drivers.calculate(payload,'','post').then(res=>{
                const _res = res.response
                const {available_amount, min_sum, max_sum, balance_after_withdraw, can_provider_sum, commission, time } = _res
                if(this.commission.time == time) {
                    this.commission = {
                        available_amount:available_amount,
                        balance_after_withdraw:balance_after_withdraw,
                        can_provider_sum:can_provider_sum,
                        commission:commission,
                        min_sum: min_sum,
                        max_sum: max_sum,
                        time: null
                    }
                    if(available_amount < max_sum){
                        this.commission.max_sum = null
                    }
                }
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })

        },
        HandleProviderSelect(selectedProvider) {
            this.provider.item = selectedProvider;
            
            if(selectedProvider.is_external_service) {
                this.getServiceList().then(res=>{
                    this.service.list = res.response
                })
            }
        },
        HandlerSelectDriver(item) {
            this.search = item;
            this.selectedDriverDetail = item
            const selectedDriver = {
                driver_id: item.id,
                park_id: item.park.id
            }
            this.apiGetDriverCard(selectedDriver)
            this.getProvider().then(res=>{
                this.updateProvider(res.response)
            })
        },
      
        HandleCardInput() {
            this.filteredCardList = this.cardLisit
        },

        HandleClick() {
            this.error = {}
            this.apiPaymantCreate()
        },

        searchRequest() {
            this.isSearchLoadding = true
            api.drivers.search({q:this.search.text}).then(res=>{
                this.driversList = res
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            }).finally(()=>{
                this.isSearchLoadding = false
            })
        },

        getServiceList() {
            const payload = {
                service:1,
                id: this.provider.item.id
            }
            this.provider.isload = true
            return api.payments.provider(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.provider.isload = false
            })
        },
        getDetail() {
            api.drivers.search({...this.search, short:true }).then(res=>{
                this.selectedDriverDetail = res.response.data[0]
                this.getProvider().then(res=>{
                    this.updateProvider(res.response)
                })
            }).catch(err=>{
                console.error(err)
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            })

            this.apiGetDriverCard({
                driver_id:this.search.id,
                park_id:this.search.park_id,
            })
            
        },
        apiGetDriverCard(payload) {
            this.cardLisitUpdated = true 
            const   {driver_id, park_id} = payload
            const param = `/${driver_id}/${park_id}?is_active=true`
            api.drivers.cards('',param).then(res=>{
                this.cardLisit = this.formatCartList(res.response.data)
                this.HandleCardInput()
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            }).finally(()=>{
                this.cardLisitUpdated = false
            })
        },
        formatCartList(list){
            let ret = []
            list.forEach(element => {
                ret.push({
                    ...element,
                    text:cc_format(''+element.number),
                })
            });

            function cc_format(value) {
                let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
                let matches = v.match(/\d{4,16}/g);
                let match = matches && matches[0] || ''
                let parts = []

                for (let i=0, len=match.length; i<len; i+=4) {
                    parts.push(match.substring(i, i+4))
                }

                if (parts.length) {
                    return parts.join('-')
                } else {
                    return value
                }
            }
            return ret
        },
        fillErrors(error) {
            this.error = {}
            error.forEach(err=>{ 
                this.error[err.field] = err.message
            })
        },
        removeCard(item){
            const params = {
                id:item.id
            }
            if(item.id == this.driversCard.id) {
                this.driversCard = {id: null, text:''}
            }
            api.card.delete({params}).then(res=>{
                if(res.status) {
                    this.$toast.success(res.message)
                } else {
                    this.$toast.error(res.message)
                }
            }).catch(err=>{
                this.$toast.error(err.message)
            }).finally(()=>{
                this.apiGetDriverCard({
                    driver_id:this.selectedDriverDetail.id,
                    park_id:this.selectedDriverDetail.park_id
                })
            })
        },
        apiPaymantCreate() {
            this.isFormLoad = true
            let payload = {
                'type':  this.account.is ? 2 : 1,
                "driver_id": this.selectedDriverDetail.id,
                "park_id": this.selectedDriverDetail.park.id,
                "card_id": this.driversCard.id,
                "amount": this.amount,
                'account': this.account.value,
                'service_id': this.service.item.id,
                'payment_provider_id': this.provider.item.id,
                'is_save_template': this.is_save_template
            }
            if(!this.driversCard.id && !this.account.is) { 
                delete payload.card_id
                payload['account']=this.driversCard.text
            }
            api.payments.create(payload,'', 'post').then(res=>{
                this.$toast.success(res.message)
                this.clear()
                this.TRIGGER_BAR('pay')
            }).catch(res=>{
                if(res.response) this.fillErrors(res.response)
                if(res.message) this.$toast.error(res.message)
                console.error(res)
            }).finally(e=>{
                this.isFormLoad = false
                eventBus.$emit('payUpdate', {
                    driver_id: this.selectedDriverDetail.id,
                    park_id: this.selectedDriverDetail.park.id,
                })
            })
        },
        clear() {
            this.error = {},
            this.search =  {
                id: null,
                text: ''
            },
            this.selectedDriverDetail = null
            this.amount = null
            this.driversCard = {
                text:''
            }
        },
        updateProvider(data) {
            const {list, selected_id} = data
            if(selected_id && selected_id!=0) {
                this.provider.item.id = selected_id
            }
            if(list.length == 1) {
                this.provider.item = list[0]
            }
            this.provider.list = list
        },
        getProvider() {
            const payload = {
                provider: 1
            }
            return api.payments.provider(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getAccount(id) {
            this.service.isload = true

            return api.payments.retrieveByService('',{id}).then(res=>{
                const length = res.response.phone_length || ''
                let mask = null
                if(res.response.phone_mask) {
                    mask = res.response.phone_mask + ' ' + (res.response.phone_mask_template).replaceAll('#','9') 
                }
                return {mask, length}
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.service.isload = false
            })
        },
        getTemplate(payload) {
            return api.payments.retrieveTemplate('',payload).then(res=>{
                return res
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        }
    },

}
</script>