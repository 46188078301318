export default {
    state: {
        currentWidth: null, // Исправлено: currentWiddth -> currentWidth
        currentDevice: null,
        isMobile: false,
        isTablet: false,
        isDesktop: false,
        isExtraDescktop: false,
        breakpoints: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            el: 2000
        }
    },
    mutations: {
        set_current_device(state) {
            // Сбрасываем значения перед обновлением
            state.isMobile = false;
            state.isTablet = false;
            state.isDesktop = false;

            // Определяем текущее устройство на основе window.innerWidth
            const width = window.innerWidth;

            if (width < state.breakpoints.sm) {
                state.currentDevice = 'xs';
                state.isMobile = true;
            } else if (width < state.breakpoints.md) {
                state.currentDevice = 'sm';
                state.isTablet = true;
            } else if (width < state.breakpoints.lg) {
                state.currentDevice = 'md';
                state.isDesktop = true;
            } else if(width < state.breakpoints.el) {
                state.currentDevice = 'lg';
                state.isDesktop = true;
            } else {
                state.currentDevice = 'el';
                state.isExtraDescktop = true;
            }

            // Обновляем текущую ширину
            state.currentWidth = width;
        }
    },
    actions: {
        SET_DEVICE_WIDTH({ commit }) {
            commit('set_current_device');
        }
    },
    getters: {
        currentDevice: state => state.currentDevice,
        isMobile: state => state.isMobile,
        isTablet: state => state.isTablet,
        isDesktop: state => state.isDesktop,
        currentWidth: state => state.currentWidth // Исправлено: currentWiddth -> currentWidth
    }
};