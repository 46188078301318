import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './modules/auth'
import profile from './modules/profile'
import references from './modules/references'
import driverReferences from './modules/driver_references'
import carReferences from './modules/car_references'
import sidebar from './modules/sidebar'
import permission from './modules/permission'
import chat from './modules/chat'
import topbar from './modules/topbar'
import deviceAdapter from './modules/deviceAdapter'

const modules = {
    auth,
    profile,
    references,
    driverReferences,
    carReferences,
    sidebar,
    chat,
    topbar,
    permission,
    deviceAdapter
}

export default new Vuex.Store({
    modules
})