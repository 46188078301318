<template>
    <div class="header">
        <div class="header__left">
            <router-link :to="{name: 'dashboard'}" class="header__name" > {{user.company_name}} </router-link>
            <v-status :value="daily" v-if="daily && perm.payment_index_management" style="margin-left: 5px ;" size="small"/>
            <p v-if="alert" class="header__alert" @click="alert = false">
                {{ alert }}
            </p>
        </div> 
        <v-mobile-side-bar class="header__right" :toggleOn="['xs','sm']">
            <v-company/>
            <v-search v-if="perm.quick_search_driver_management"/>
            <v-trans v-if="perm.quick_transaction_button_management"/>
            <v-pay v-if="perm.quick_payment_button_management" />
            <v-chat v-if="perm.quick_chat_management"/>
            
            <!-- <v-notification :count="user.new_notifications || 0" v-if="perm.quick_notification_management"/> -->
            <v-user v-if="user && perm" :name="user.name" :perm="perm"/>
            <!-- <v-user-status @update="HandlerStatusUpdate($event)" v-if="perm.quick_change_status_management"/> -->
        </v-mobile-side-bar>

        
    </div>
</template>

<script> 
import api from '@api'
import { eventBus } from '@eventBus'
import { mapState, mapActions } from 'vuex'
import chatsocket from './socket'

import storeReset from '../../router/views/storeReset'
import telSocket from './socket_tyelephony'
import socket_table from './socket_table'
import vChat from './modules/HeaderChatNotification' 
import vCompany from './modules/Company'

import vNotification from './modules/HeaderNotification' 
import vPay from './modules/HeaderQuickPay'
import vSearch from './modules/HeaderQuickSearch'
import vTrans from './modules/HeaderTransactions'
import vUser from './modules/HeaderUser'
import vUserStatus from './modules/HeaderUserStatus'
import vMobileSideBar from '@components/global/mobile/sideBarMobile'

import vStatus from '../../router/views/payments/modules/status'

export default {
    name:'vHeader',
    components: { 
        vChat,
        vNotification, 
        vUser,
        vUserStatus,
        vPay,
        vTrans,
        vSearch,
        vCompany,
        
        
        vStatus,
        vMobileSideBar
    },
    props: {
        alert: {
            type: [String, Boolean],
            default: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.profile.user,
            perm: state => state.profile.perm
        }),
    },
    mixins: [chatsocket,storeReset,telSocket, socket_table],
    data() {
        return {
            status: [],
            userMenu: [],
            isEndCall: false,
           
            daily: null,
            timer: null
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    }, 
    mounted() {
        window.addEventListener("resize", this.myEventHandler);
        window.dispatchEvent(new Event('resize'));
        
        
        eventBus.$on('readyReport', this.socketReadyReport);
        eventBus.$on('errorReport', this.socketerrorReport);
        this.getRef()
        this.initStatus()
    },
    methods: {
        ...mapActions([
            'GET_REFRENCES',
            'UPDATE_PROFILE'
        ]),
        socketReadyReport(e) {
            this.$toast.success(e.message)
        },
        socketerrorReport(e) {
            this.$toast.error(e.message)
        },
        HandlerStatusUpdate(status) {
            this.UPDATE_PROFILE({status})
        }, 
        
        getRef() {
            this.GET_REFRENCES().then(response=>{
                this.initSocket()
                this.tel_initSocket()
                this.tab_initSocket()
            }).catch(error=>{
                console.error(error);
                if(error.code == 401) {
                    eventBus.$emit('globalLogout')  
                } 
            })
        },
        initStatus() { 
            const _this = this
            _this.getSummary().then(res=>{
                _this.daily = res.daily
            }) 

            this.timer = setInterval(function() {
                _this.getSummary().then(res=>{
                    _this.daily = res.daily
                }) 
            }, 20000);
        },
        getSummary(payload) {
            this.isloading = false
            return api.payments.summary(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
                if(this.timer) clearInterval(this.timer)
            }).finally(e=>{
                this.isloading = false
            })
        },
        myEventHandler(e) {
            if(this.headerHeight != this.$el.clientHeight) {
                this.headerHeight = this.$el.clientHeight
                this.$emit('headerHeightChange',this.headerHeight)
            }
        },
        
        
    },
    
}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';

.header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding-left: 52px;
    //height: 64px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #CBCCCF;
    background-color: #fff;
    
    
    @media (min-width: 1500px) {
        padding-left: 82px;
    }
    
    &__right {
        flex:1 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;
        background: $global-white;
        
        @media (min-width: 1500px) {
            padding-right: 32px;
            padding-left: 32px;
        }
    }
    
    &__left {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;
        //max-width: 50%;
        
        @media (min-width: 1500px) {
            padding-right: 32px;
            padding-left: 32px;
        }
    }
    
    &__name {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #0A0A17; 
    }
    &_button_icon {
        display: flex;
        align-items: center;
    }

    &__alert {
        background-color: #E94A4A;
        color: #fff;
        margin-left: 15px;
        padding: 5px;
        border-radius: 5px;
    }
}
</style>