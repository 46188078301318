<template>
    <div>
        <v-modal v-if="!!call" :show="!!call" @close="call = false">
            <p slot='header'> {{ call.title }} </p>
            <div slot="body">
                <p v-if="call.candidate_info" style="font-size:20px;">
                    {{ call.candidate_info.text }}
                </p>
                <p v-if="call.driver_info" style="font-size:20px;">
                    {{ call.driver_info.text }}
                </p>
            </div>
            <div slot="footer" class="flex-right">
                <button class="button button_size_ex-large button_color_white" @click='handleReset'>Закрыть</button>
                <button class="button button_size_ex-large button_color_black" @click="handleApply(call)">Перейти</button>
            </div>
        </v-modal>
        <v-end-call-modal v-if="!!uuid" @close="uuid = false" :uuid="uuid" />
    </div>
</template>

<script>
import vEndCallModal from '@components/global/vEndCallModal.vue'
import VModal from '@components/global/Modal'

import { eventBus } from '@eventBus'
export default {
    components: { VModal, vEndCallModal },
    data() {
        return {
            uuid: '',
            call: null,
        }
    },
    mounted() {
        eventBus.$on('incomingCall', this.incomingCall);
        eventBus.$on('endCall', this.endCall);
    },
    methods: {
        incomingCall(e) {
            const { action, type, driver_info, candidate_info, company_id, user_id, uuid, title } = e
            this.call = { action, type, driver_info, candidate_info, company_id, user_id, uuid, title }
        },

        handleReset() {
            this.call = null
        },
        endCall(e) {
            this.uuid = e.uuid
        },
        handleApply(call) {
            let route = null
            if (call.type == 'candidate') {
                const { id } = call.candidate_info
                route = { name: 'CandidateEdit', query: { id } }
            } else {
                const { id, park_id } = call.driver_info
                route = { name: "DriverDetail", query: { driver_id: id, park_id } }
            }
            this.$router.push(route)
            this.handleReset()
        },

    },
}
</script>

<style lang="scss" scoped></style>