<template>
    <div class="header-user">
         <button class="header-user__button button button_size_large button_color_transp-black button_with_icon "
         @click="menuExpand=!menuExpand" v-click-outside="()=>{menuExpand=false}">
            <svg aria-hidden="true" width="20" height="20">
                <use href="#icon-user"></use>
            </svg>
            <span>
                {{name}}
            </span>

            <svg aria-hidden="true" width="12" height="12">
                <use href="#icon-arrow-down"></use>
            </svg>
        </button>
        <v-list
            v-if="menu"
            class="header-user__dropList"
            :list="menu"
            :expand="menuExpand"
            @click="handleClick($event)"
        />

    </div>
</template>

<script>
import vList from './../../global/DropDownList'
import storeReset from '../../../router/views/storeReset'
import { eventBus } from '@eventBus'
export default {
    mixins: [storeReset],
    components: {
        vList
    },
    props: {
        name: {
            type: String,
            default: 'name'
        },
        perm: {
            type: Object,
            default: () => {}
        }
        
    },
    data() {
        return {
            menuExpand: false,
            menu: false,
        }
    },
    mounted(){
        this.menu = [{
            id: 'cabinet',
            text: 'Персональный кабинет',
            route: {name:'Personal'},
            sort: 2
        },{
            id: 'logout',
            text: 'Выход',
            class: 'gray',
            sort: 10
        }] 
        if(this.perm){
            if(this.perm.full_dashboard_overdraft_management) {
                const company = {
                    id: 'overdraft',
                    text: 'Овердрафт',
                    route: {name:'overdraft-new'},
                    sort: 6
                }
                this.menu.unshift(company)     
            }
            if(this.perm.dashboard_index_management) {
                const company = {
                    id: 'company',
                    text: 'Управление компанией',
                    route: {name:'Company'},
                    sort: 1
                }
                this.menu.unshift(company)
            }
            if(this.perm.callcenter_access) {
                const company = {
                    id: 'call-center',
                    text: 'Управление колл-центром',
                    route: {name:'PhrazeCategoriesList'},
                    sort: 3
                }
                this.menu.unshift(company)
            }
            if(this.perm.reports) {
                const company = {
                    id: 'personal-reports',
                    text: 'Отчеты',
                    route: {name:'CabinetReportsDriverList'},
                    sort: 4
                }
                this.menu.unshift(company)
            }
            if(this.perm.payment_system_management) {
                const company = {
                    id: 'payments',
                    text: 'Вывод средств',
                    route: {name:'CabinetPaymentsList'},
                    sort: 5
                }
                this.menu.unshift(company)
            }
            this.menu = this.sort(this.menu)
        }
    },
    methods: {
        handleClick(item) {
            switch (item.id) {
                case 'logout':
                    eventBus.$emit('globalLogout')
                    break;
                default:
                    this.$router.push('/')
                    break;
            }
        },
        sort(array) {
            return array.sort((a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                }
                if (a.sort > b.sort) {
                    return 1;
                }
                return 0;
            });
        }
    },
}
</script>

<style lang="scss">
@import '../../../../../gulp-src/app/scss/_variables';

.header-user {
    position: relative;
    &__button{
        background-color: transparent;
        margin-right: 0;
        
        @media (min-width: 1500px) {
            margin-right: 20px;
        }

        span {
            color:#0A0A17;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0;
            @media screen and (min-width: 960px) {
                font-size: unset;
            }
        }
    }

    &__dropList{
        right: 20px;

        li {
            white-space: nowrap;
        }

        .gray {
            color: #919197;

            &:hover {
                color: #0A0A17;
            }
        }
    }
}

</style>